import axios from "axios";

export const DataService = {
    getDataByKey,
};



function getDataByKey(key) {

    console.log(axios.defaults,key)
    axios.defaults.headers = { 'Content-Type': 'application/json' ,'Authorization' : "Bearer "+ JSON.parse(localStorage.getItem('user')).token }

    return axios.get(''+key).then((res) => {return res})

}


//
// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 location.reload(true);
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }
