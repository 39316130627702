import {DataService} from '../../helpers/fakebackend/data.service';

const resData = JSON.parse(localStorage.getItem('resData'));
export const state = resData
    ? {status: {getDataSuccess: true}, resData}
    : {status: {}, resData: null};

export const actions = {

    getData({dispatch, commit}, key) {
        //commit('loginRequest', { key });

        return DataService.getDataByKey(key)
            .then(
                (res) => {
                    commit('GetDataSuccess', res);
                    return res
                },
                error => {
                    commit('GetDataFailure', error);
                    dispatch('notification/error', error, {root: true});
                }
            );
    },

};

export const mutations = {
    loginRequest(state) {
        state.status = {loggingIn: true};
        state.resData = resData;
    },
    GetDataSuccess(state, resData) {
        state.status = {getDataSuccess: true};
        state.resData = resData;
    },
    GetDataFailure(state) {
        state.status = {};
        state.resData = null;
    },

};

